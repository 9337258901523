<template>
  <div class="w-full contents">
    <div class="pb-2">
      <div class="title">Nomination Information</div>
      <div>
        <el-row>
          <el-col :span="24" :md="10">
            <div class="flex flex-row">
              <div class="label1">Customer :</div>
              <div class="v">{{ data.customerCompanyName }}</div>
            </div>
            <div class="flex flex-row">
              <div class="label1">Product :</div>
              <div class="v">{{ data.product }}</div>
            </div>
            <div class="flex flex-row">
              <div class="label1">Tank :</div>
              <div class="v">{{ data.tankName }}</div>
            </div>
            <div class="flex flex-row">
              <div class="label1">Jetty Number :</div>
              <div class="v">{{ data.jettyNo }}</div>
            </div>
          </el-col>
          <el-col :span="24" :md="14">
            <div class="flex flex-row">
              <div class="label2">Barge Name (IMO No.) :</div>
              <div class="v">{{ data.vesselName }} ({{ data.bargeName }})</div>
            </div>
            <div class="flex flex-row">
              <div class="label2">Nominated Quantity :</div>
              <div class="v">{{ data.nominatedQtyMax }}</div>
            </div>
            <div class="flex flex-row">
              <div class="label2">Berth Assign Timing :</div>
              <div class="v">{{ data.berthAssignTiming }}</div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "NominationInformation",
  props:  {
    data: {
      type: Object
    }
  },
  data() {
    return {

    }
  }
}
</script>
<style lang="scss" scoped>
.contents {
 color: #1D1B20;
 font-family: 'Roboto';

  .title {
    font-size: 14px;
    font-weight: 800;
    margin-bottom: 10px;
  }

  .flex {
    margin-bottom: 5px;
  }

  .label1 {
    font-size: 12px;
    font-weight: 800;
    width: 80px;
  }

  .label2 {
    font-size: 12px;
    font-weight: 800;
    width: 130px;
  }

  .v {
    font-size: 11px;
    font-weight: 600;
  }

}
</style>
<template>
  <div class="w-full" v-loading="loading">
    <div>
      <el-row>
        <el-col :span="24" :md="12" class="pr-1">
          <div class="title">Berthing</div>
          <el-form :model="formData[0]" size="mini" :disabled="disabled">
            <el-form-item label="Pilot:" prop="pilot">
              <el-select v-model="formData[0].pilot" class="w-full" clearable>
                <el-option v-for="item in pilotList" :key="item.name" :value="item.name" :label="item.name"/>
              </el-select>
            </el-form-item>
            <el-form-item label="Name of Tug Boat (1):" prop="nameOfTugBoat1">
              <el-select v-model="formData[0].nameOfTugBoat1" class="w-full" clearable>
                <el-option v-for="item in tugBoatList" :key="item.name" :value="item.name" :label="item.name"/>
              </el-select>
            </el-form-item>
            <el-form-item label="Name of Tug Boat (2):" prop="nameOfTugBoat2">
              <el-select v-model="formData[0].nameOfTugBoat2" class="w-full" clearable>
                <el-option v-for="item in tugBoatList" :key="item.name" :value="item.name" :label="item.name"/>
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="24" :md="12" class="pl-1">
          <div class="title">Un-Berthing</div>
          <el-form :model="formData[1]" size="mini" :disabled="disabled">
            <el-form-item label="Pilot:" prop="pilot">
              <el-select v-model="formData[1].pilot" class="w-full" clearable>
                <el-option v-for="item in pilotList" :key="item.name" :value="item.name" :label="item.name"/>
              </el-select>
            </el-form-item>
            <el-form-item label="Name of Tug Boat (1):" prop="nameOfTugBoat1">
              <el-select v-model="formData[1].nameOfTugBoat1" class="w-full" clearable>
                <el-option v-for="item in tugBoatList" :key="item.name" :value="item.name" :label="item.name"/>
              </el-select>
            </el-form-item>
            <el-form-item label="Name of Tug Boat (2):" prop="nameOfTugBoat2">
              <el-select v-model="formData[1].nameOfTugBoat2" class="w-full" clearable>
                <el-option v-for="item in tugBoatList" :key="item.name" :value="item.name" :label="item.name"/>
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      
    </div>
  </div>
</template>
<script>
import { getPilotList, getTugBoatList, getFormTugBoat, saveFormTugBoat } from "@/services/form"
export default {
  name: "Berthing",
  props: {
    id: {
      type: Number
    },
    disabled: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      formData: [
        {
          pilot: "",
          nameOfTugBoat1: "",
          nameOfTugBoat2: "",
          type: "berthing"
        },
        {
          pilot: "",
          nameOfTugBoat1: "",
          nameOfTugBoat2: "",
          type: "un-berthing"
        },
      ],
      loading: true,
      pilotList: [],
      tugBoatList: [],
    }
  },
  async created() {
    await this.getOptionList()
    await this.getDetail()
    this.loading = false
  },
  mounted() {
    this.$emit('save', this.save)
  },
  methods: {
    async getOptionList() {
      await getPilotList().then(res => {
        this.pilotList = res.data.table.rows
      });
      await getTugBoatList().then(res => {
        this.tugBoatList = res.data.table.rows
      });
    },
    async getDetail() {
      await getFormTugBoat({ nominationId: this.id }).then(res=> {
        const tugBoatList = res?.data?.tugBoatList
        if(tugBoatList) {
          tugBoatList.map(item => {
            if(item.type=="berthing") {
              this.formData[0].pilot = item.pilot
              this.formData[0].nameOfTugBoat1 = item.nameOfTugBoat1
              this.formData[0].nameOfTugBoat2 = item.nameOfTugBoat2
            } else if(item.type=="un-berthing") {
              this.formData[1].pilot = item.pilot
              this.formData[1].nameOfTugBoat1 = item.nameOfTugBoat1
              this.formData[1].nameOfTugBoat2 = item.nameOfTugBoat2
            }
          })
        }
      })
    },
    async save() {
      this.loading = true
      
      const data = {
        nominationId: this.id,
        tugBoatList: this.formData,
      }

      await saveFormTugBoat( data ).then(async res => {
        await this.getDetail()
        this.$message.success('Save Tug Boat Success')
      }).finally(() => {
        this.loading = false
      })
    },
  }
}
</script>
<style scoped >
.title {
  font-family: 'Roboto';
  color: #1D1B20;
  font-size: 14px;
  font-weight: 800;
  margin-bottom: 10px;
}
::v-deep .el-form-item--mini .el-form-item__label {
  color: #1D1B20;
  font-size: 11px;
  font-weight: 600;
}
::v-deep .el-form-item--mini.el-form-item {
  margin-bottom: 8px;
}
</style>
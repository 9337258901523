<template>
  <div class="w-full">
    <div class="pb-2 pt-4 px-4">
      <div class="title">Equipment Check</div>
      <div class="w-full">
        <el-row>
          <el-col :span="24" :md="12">
            <div class="w-full pb-2">
              <div class="w-full" style="overflow-x: auto;">
                <el-table :data="list" style="min-width: 280px !important; width: 100% !important;">
                  <el-table-column prop="formDescription" label="Equipment"/>
                  <el-table-column label="In Good Working Order?" width="150">
                    <template slot-scope="scope">
                      <el-checkbox-group v-model="scope.row.check" :disabled="true">
                        <el-checkbox label="1">Yes</el-checkbox>
                        <el-checkbox label="0">No</el-checkbox>
                      </el-checkbox-group>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </el-col>
          <el-col :span="24" :md="12">
            <div class="w-full pb-2 pl-2">
              <div class="f-600">
                Any defects in, and any matter peculiar to the ship and its machinery and equipment of which the Master knows
                and which might materially affect the navigation of the ship:
              </div>
              <el-input v-model="pilotMovementMatter" :maxlength="600" :rows="5" class="mt-4" show-word-limit type="textarea" :disabled="true"></el-input>
              <div class="mt-4" style="font-size: 12px; font-weight: 800;">
                NOTE: The Master shall at times bear the full responsibility of the vessel’s movements in and out of the
                terminal and any liability arising in connection therewith. The Pilot shall act only as a navigation adviser
                to the Master of the vessel for movements of the vessel in and out of the terminal but the Master remains
                solely and entirely responsible for any and all steps, action and omission connected with such movements.
                Where a tug is engaged to assist in the berthing or unberthing of the vessel, the tug, her owners and/or the
                charterers shall not be liable for any loss or damage whatsoever and howsoever caused by the acts and
                omissions (including any act or omission of a negligent nature) on the part of her Mater and crew, the Pilot
                on board, her owners and charterers. It is further understood that this provision is intended for the benefit
                of the tug, her owners and charterers, for the purposes of the Contracts (Rights of Third Parties) Act, even
                if these parties may not be in any contractual relationship with the vessel, her owners or charterers, cargo
                interests or any other party interested in the shipment.
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="w-full">
        <div class="f-600 mt-4">I hereby declare that the information provided above is to the best of my knowledge true
          and correct.
        </div>
        <div class="mt-4" style="color:#1D1B20; font-weight: 800;">Signature</div>
        <div class="flex flex-wrap w-full xl:w-1/2 mt-4 justify-between">
          <div class="w-full mt-4 sm:w-1/2 sm:mt-0">
            <div class="f-600">Barge Representative</div>
            <div class="mt-4 f-600" style="font-size: 12px">
              <span>Name:</span>
              <span class="ml-2">{{sign.barge.name}}</span>
            </div>
            <div class="mt-4 f-600" style="font-size: 12px">
              <span>Date and Time:</span>
              <span class="ml-2">{{sign.barge.createTime}}</span>
            </div>
            <div class="mt-4 f-600 flex" style="font-size: 12px">
              <span>Signature:</span>
              <img v-if="sign.barge.eSignature" :src="sign.barge.eSignature" class="ml-2" style="width: 100px;" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { selectFromById } from "@/services/form";
export default {
  name: "PilotMovement",
  props:  {
    id: {
      type: Number
    },
    sign:{
      type: Object
    },
  },
  data() {
    return {
      list: [],
      pilotMovementMatter: '',
    }
  },
  async created() {
    await this.getDetail()
  },
  methods: {
    async getDetail() {
      await selectFromById({ nId: this.id, fId: 6 })
      .then(res => {
        this.list = res.data.list.map(item=>{
          return {
            ...item,
            check:item.check?item.check.split(','):[]
          }
        })
        this.pilotMovementMatter = res.data.pilotMovementMatter
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.title {
  font-family: 'Roboto';
  color: #1D1B20;
  font-size: 14px;
  font-weight: 800;
  margin-bottom: 10px;
}
::v-deep.el-table::before {
  height: 0px;
}
::v-deep.el-table thead tr {
  background-color: #F4F8FB;
}
::v-deep.el-table th.el-table__cell.is-leaf {
  background-color: transparent;
  font-size: 12px;
  font-weight: 800;
  color: #1D1B20;
}
::v-deep.el-table td.el-table__cell {
  font-size: 11px;
  font-weight: 400;
  color: #1D1B20;
  border: 1px solid #82889C;
  border-bottom: none;
}
::v-deep.el-table td.el-table__cell .cell, ::v-deep.el-checkbox .el-checkbox__label {
  font-size: 11px;
  font-weight: 800;
  color: #1D1B20;
}
::v-deep.el-table td.el-table__cell:first-child {
  border-right: none;
}
::v-deep.el-table td.el-table__cell:last-child {
  border-left: none;
}
::v-deep.el-table tr:last-child td.el-table__cell {
  border-bottom: 1px solid #82889C;
}
::v-deep.el-checkbox .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  background-color: #008CDB;
  border-color: #008CDB;
}
::v-deep.el-checkbox .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
    border-color: white;
}
</style>
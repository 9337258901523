<template>
  <div class="w-full" v-loading="loading">
    <div class="p-2 text-right">
      <el-button v-if="! disabled" type="primary" size="mini" :loading="saveButtonLoading" @click="handleSave">Save</el-button>
      <el-button plain type="primary" size="mini" @click="backToList">Close</el-button>
    </div>
    <div class="pilot-container">
      <el-row>
        <el-col :span="24" :md="10" >
          <div class="left">
            <div v-if="createdtable != null" style="padding: 20px 20px 10px;">
              <NominationInformation :data="createdtable" />
            </div>
            <div v-if="status != null">
              <StatusLine :key="statusLineKey" :status="status" :loadingType="loadingType" />
            </div>
            <div style="border-top: 1px solid #D5DDED; padding: 20px;">
              <TimeSheet :id="id" :disabled="disabled" :nominationData="createdtable" @save="(cb) => saveTimeSheet = cb"/>
            </div>
            <div style="padding: 20px;">
              <Berthing :id="id" :disabled="disabled" @save="(cb) => saveBerthing = cb"/>
            </div>
            <div style="padding: 20px;">
              <Remarks :id="id" :disabled="disabled" :remarks="createdtable.pilotRemarks" @save="(cb) => saveRemarks = cb"/>
            </div>
            <div style="padding: 20px;">
              <ReportEquipment :id="id" :disabled="disabled" @save="(cb) => saveReportEquipment = cb"/>
            </div>
          </div>
        </el-col>
        <el-col :span="24" :md="14">
          <div class="right">
            <div class="w-full">
              <div :class="getActiveClass1" style="border-right: 1px solid #D5DDED;" @click="activeTab = 1">
                Vessel's Checklist for Pilot Movement
              </div>
              <div :class="getActiveClass2" @click="activeTab = 2">
                Pilot Ladder Declaration
              </div>
            </div>
            <div class="w-full">
              <PilotMovement v-if="activeTab==1" :id="id" :sign="sign"/>
              <PilotLadderDeclaration v-if="activeTab==2" :id="id" :sign="sign" />
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import NominationInformation from "./components/NominationInformation.vue";
import TimeSheet from "./components/TimeSheet.vue";
import ReportEquipment from "./components/ReportEquipment.vue";
import PilotMovement from "./components/PilotMovement.vue";
import PilotLadderDeclaration from "./components/PilotLadderDeclaration.vue";
import StatusLine from "./components/StatusLine.vue";
import Berthing from "./components/Berthing.vue";
import {formall} from "@/services/user";
import Remarks from "./components/Remarks.vue";
export default {
  name: "Pilot",
  components: {
    NominationInformation,
    TimeSheet,
    ReportEquipment,
    PilotMovement,
    PilotLadderDeclaration,
    StatusLine,
    Berthing,
    Remarks,
  },
  computed: {
    getActiveClass1() {
      return ['pilot-tabs', this.activeTab == 1 ? 'active' : '']
    },
    getActiveClass2() {
      return ['pilot-tabs', this.activeTab == 2 ? 'active' : '']
    }
  },
  data() {
    return {
      id: null,
      activeTab: null,
      createdtable: null,
      status: null,
      loadingType: null,
      disabled: true,
      saveTimeSheet: null,
      saveBerthing: null,
      saveRemarks:null,
      saveReportEquipment: null,
      sign: {
        barge: {
          name: null,
          createTime: null,
          eSignature: null,
        }
      },
      loading: true,
      saveButtonLoading: false,
      statusLineKey: 0,
    }
  },
  async created() {
    this.id = parseInt(this.$route.query.id)
    if( this.checking() ) {
      await this.getDetail()
      this.activeTab = 1
    }
  },
  methods: {
    checking() {
      if( ! this.idNotEmpty() || ! this.isPilot() ) {
        this.backToList()
        return false
      }
      return true
    },
    idNotEmpty() {
      return this.id != null && this.id != undefined && this.id > 0
    },
    isPilot() {
      return this.$store.state.userInfo.userType == '1' && ['10','12'].includes(this.$store.state.userInfo.role)
    },
    isValidStatus() {
      return [2,3,4].includes(this.createdtable.status)
    },
    isJettyAssigned() {
      return this.createdtable.status == 2 && this.createdtable.loadingType == null
    },
    backToList() {
      this.$router.push({ path: "/barging/index" })
    },
    async getDetail() {
      await formall(this.id).then((res) => {
        this.createdtable = res.data;
        if(this.isValidStatus()) {
          this.setBargeSign()
          this.loading = false
          this.status = this.createdtable.status
          this.loadingType = this.createdtable.loadingType
          this.disabled = [0,1,4,5].includes(this.createdtable.status) || this.isJettyAssigned()
          this.statusLineKey++
        } else {
          this.backToList()
        }
      });
    },
    setBargeSign() {
      if( this.createdtable.formsSigns ) {
        this.sign.barge = this.createdtable.formsSigns.filter(item => item.signType == 1&&item.type==1)[0] || this.sign.barge
      }
    },
    async handleSave() {
      this.saveButtonLoading = true
      if(typeof this.saveTimeSheet === 'function') {
        await this.saveTimeSheet()
      }
      if(typeof this.saveBerthing === 'function') {
        await this.saveBerthing()
      }
      if(typeof this.saveRemarks === 'function') {
          await this.saveRemarks()
      }
      if(typeof this.saveReportEquipment === 'function') {
        await this.saveReportEquipment()
      }
      await this.getDetail()
      this.saveButtonLoading = false
    }
  }
}
</script>
<style lang="scss" scoped>
.pilot-container {
  width: 100% !important;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #D5DDED;
  font-size: 11px;
  color: #1D1B20;
  font-family: 'Roboto';

  .left {
    border-right: 1px solid #D5DDED;
  }

  .pilot-tabs {
    width: 50%;
    border-bottom: 1px solid #D5DDED;
    padding: 20px;
    display: inline-block;
    text-align: center;
    font-size: 12px;
    font-weight: 800;
    position: relative;
    cursor: pointer;
  }

  .pilot-tabs.active {
    color: #008CDB;
  }

  .pilot-tabs.active::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 5px;
    background-color: #008CDB;
    transition: all 0.15s;
  }
}
</style>
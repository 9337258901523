<template>
  <div class="w-full contents">
    <div class="pb-2 pt-4 px-4">
      <div class="w-full flex flex-wrap xl:w-4/5">
        <div class="w-full xl:w-1/3 flex items-center">
          <span>1<span style="color:red">*</span>, </span>
          <el-input v-model="form.name" class="ml-2 mr-2" placeholder="Name" size="mini" :disabled="true"></el-input>
          <span style="white-space: nowrap"> ,Master of<span style="color:red">* </span></span>
        </div>
        <div class="w-full mt-2 mb-2 xl:w-1/3 flex items-center xl:m-0 ">
          <el-input v-model="form.vesselName" placeholder="Vessel Name" size="mini" :disabled="true"></el-input>
        </div>
        <div class="w-full xl:w-1/3 flex items-center">
          <span> ,at<span style="color:red">* </span></span>
          <el-date-picker v-model="form.time" class="ml-2 mr-2" format="yyyy-MM-dd HH:mm:ss" 
            size="mini" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="Date and Time"
            :disabled="true"
          ></el-date-picker>
          <span style="white-space: nowrap"> ,declares that</span>
        </div>
      </div>
      <div class="mt-4" style="line-height: 22.5px">
        1.I comply to SOLAS Chapter 1, Regulations 8 on the Inspection/Survey Regime of Pilot Ladders.<br/>
        2.Pilot Ladders have a valid certificates that shows compliances to relevant standards such as ISO
        799-1:2019.<br/>
        3.Pilot Ladder is periodically inspected and replaced when damaged or not more than <span style="color: red">30 months</span>.<br/>
        4.Inspection, Replacement and Maintenance Records of Pilot Ladders are readily available for sharing when
        required.<br/>
        5.I comply to SOLAS Chapter 5, Regulations 23 on the Pilot Transfer Arrangements.<br/>
        6.I comply to the required boarding arrangements for Pilot published by SOLAS/IMO.<br/>
        7.Pilot Ladder in used is in good working condition and is inspected by a competent crew before any use by JPUT’s
        Employee.<br/>
        8.Pilot Ladder in used had not been prematurely damaged by D-Shackle rigging.<br/>
        9.Pilot Ladder in used is secured to the ship’s deck on designated strong points, with the use rolling hitch knot
        to secure the pilot ladder.<br/>
        10.Vessel Crew is trained to use life buoy and both crew and life buoy is on standby when pilot ladder is being
        used.<br/>
        11.Adequate lighting is provided at pilot ladder access.<br/>
        <br/>
        Sign off by Master
      </div>
      <div class="mt-4" style="color:#1D1B20; font-weight: 800;">
        Signature
      </div>
      <div class="flex flex-wrap w-full xl:w-1/2 mt-4 justify-between">
        <div class="w-full mt-4 sm:w-1/2 sm:mt-0">
          <div class="f-600">Barge Representative</div>
          <div class="mt-4 f-600" style="font-size: 12px">
            <span>Name:</span>
            <span class="ml-2">{{sign.barge.name}}</span>
          </div>
          <div class="mt-4 f-600" style="font-size: 12px">
            <span>Date and Time:</span>
            <span class="ml-2">{{sign.barge.createTime}}</span>
          </div>
          <div class="mt-4 f-600 flex" style="font-size: 12px">
            <span>Signature:</span>
            <img v-if="sign.barge.eSignature" :src="sign.barge.eSignature" class="ml-2" style="width: 100px;" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { selectFromById } from "@/services/form";
export default {
  name: "PilotLadderDeclaration",
  props:  {
    id: {
      type: Number
    },
    sign:{
      type: Object
    },
  },
  data() {
    return {
      form: {
        name: "",
        vesselName: "",
        time: "",
      },
    }
  },
  async created() {
    await this.getDetail()
  },
  methods: {
    async getDetail() {
      await selectFromById({ nId: this.id, fId: 7 })
      .then(res => {
        this.form = res.data.declarationPilotLadder
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.contents {
  color: #1D1B20;
  font-family: 'Roboto';
  font-size: 11px;
  font-weight: 600;
}
</style>
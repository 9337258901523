<template>
    <div class="w-full" v-loading="loading">
        <div class="title">Remarks</div>
        <el-form :model="form" :disabled="disabled">
            <el-form-item label="">
                <el-input v-model="form.remarks" type="textarea" />
            </el-form-item>
        </el-form>
    </div>
  </template>
  <script>
  import { savePilotRemarks } from "@/services/form"
  export default {
    name: "Remarks",
    props: {
      id: {
        type: Number
      },
      disabled: {
        type: Boolean,
        default: true,
      },
      remarks:{
        type:String,
      }
    },
    data() {
      return {
        form: {remarks: this.remarks},
        loading: true,
      }
    },
    async created() {
      this.loading = false
    },
    mounted() {
      this.$emit('save', this.save)
    },
    methods: {
      async save() {
        this.loading = true
        
        const data = {
            id: this.id,
            pilotRemarks:this.form.remarks,
        }
  
        await savePilotRemarks( data ).then(async res => {
          this.$message.success('Save Remarks Success')
        }).finally(() => {
          this.loading = false
        })
      },
    }
  }
  </script>
  <style scoped >
  .title {
    font-family: 'Roboto';
    color: #1D1B20;
    font-size: 14px;
    font-weight: 800;
    margin-bottom: 10px;
  }
  ::v-deep .el-form-item--mini .el-form-item__label {
    color: #1D1B20;
    font-size: 11px;
    font-weight: 600;
  }
  ::v-deep .el-form-item--mini.el-form-item {
    margin-bottom: 8px;
  }
  </style>